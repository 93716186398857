import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import isconnected from "../../service/isconnected";
import Footer from "../Footer/Footer";
import Header from "../header/Header";
import Actualite from "./section/Actualite";
import Partenaire from "./section/Partenaire";
import Popinsc from "./section/Popinsc";
import Section1 from "./section/Section1";
import Section2 from "./section/Section2";
import Section3 from "./section/Section3";
import Section4 from "./section/Section4";
import SuggestionModule from "./section/SuggestionModule";
import { useDispatch, useSelector } from "react-redux";
import MotMinistre from "./section/MotMinistre";
import { getMots } from "../../service/CallApi/getMots";

export default function Accueil(props) {
  const context = useOutletContext()
  const navigate =useNavigate()
  const dataRedux = useSelector(state=>state.compte)

useEffect(() => {
  getMots();
}, [])



  return (   
    <div className="bg-light h-100">
  
      <div className="m-0 p-0">
      <Section1 />
      <Actualite />
      <Section4 />
      {context?.notlog || dataRedux?.type_util == "Grand public" ? <></> :<Section2 /> }
      {context?.notlog || dataRedux?.type_util == "Grand public" ? <></> : <SuggestionModule test={props.test}/>}
      {context?.notlog || dataRedux?.type_util == "Grand public" ?  <></> : <Section3 test={props.test} categ={props.categorie}/> }
      <Partenaire />
      </div>
    </div>
  );
}
