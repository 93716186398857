import React from "react";
import image from "../../../assets/image/imgtest/image";

export default function Partenaire() {
  return (
    <div className="container-lg container-fluid mt-3 py-3 pb-5">
      <div className="titre text-center m-4">
        <p className="h1">
          <div className="vr w-2 bg-primary me-3"></div> Partenaires{" "}
          <div className="ms-3 vr w-2 bg-primary"></div>{" "}
        </p>
      </div>
      <div className="titre text-center m-4">
        <p className="h3">Projet en partenariat avec</p>
      </div>
      <div className="d-flex flex-wrap justify-content-center gap-5">
        <div className="d-flex flex-column justify-content-center align-items-center text-center effet p-2">
          <a
            href="https://www.facebook.com/ATTMada"
            target={"_blank"}
            className="text-decoration-none"
          >
            <img
              className="anime-effet img-fluid"
              src={image.pact}
              alt="PACT Logo"
            />
            <p className="fw-bold py-2"></p>
          </a>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center text-center effet p-2">
          <a
            href="https://www.facebook.com/ATTMada"
            target={"_blank"}
            className="text-decoration-none"
          >
            <img
              className="anime-effet img-fluid"
              src={image.att}
              style={{ maxHeight: "80px", width: "auto" }}
              alt="ATT Logo"
            />
            <p className="fw-bold py-2"></p>
          </a>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center text-center effet p-2">
          <a
            href="https://www.facebook.com/ATTMada"
            target={"_blank"}
            className="text-decoration-none"
          >
            <img
              className="anime-effet img-fluid"
              src={image.mtm}
              style={{ maxHeight: "70px", width: "auto" }}
              alt="MTM Logo"
            />
            <p className="fw-bold py-2"></p>
          </a>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-start text-center effet p-2">
          <a
            href="https://www.facebook.com/ATTMada"
            target={"_blank"}
            className="text-decoration-none pb-4"
          >
            <img
              className="anime-effet img-fluid "
              src={image.metfp}
              style={{ maxHeight: "90px", width: "auto" }}
              alt="METFP Logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
