const actu = [];
const sensi = [];
const categorie = [];
const suggestion = [];
const exomod = []
const languechoisi = "Français"
const allLesson = []
const allExercice = []
const initArray1 = [];
const initArray2 = [];
const mots={};
const user = {};

function actualite(state = actu, action) {
  switch (action.type) {
    case "add":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function getMots(state = mots, action) {
  switch (action.type) {
    case "addmots":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clearmots":
      return {};
    default:
      return state;
  }
}

function sensibilisation(state = sensi, action) {
  switch (action.type) {
    case "addsens":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function categ(state = categorie, action) {
  switch (action.type) {
    case "addcateg":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function Getlesson(state = allLesson, action) {
  switch (action.type) {
    case "AddLesson":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function Getexercice(state = allExercice, action) {
  switch (action.type) {
    case "addexo":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}


function recommandation(state = suggestion, action) {
  switch (action.type) {
    case "addsuggestion":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function ExoModule(state = exomod, action) {
    switch (action.type) {
      case "addexomod":
        return action.payload;
      case "remove":
        return state.filter((item) => item != action.payload);
      case "clear":
        return [];
      default:
        return state;
    }
  }

  function ChangerLangue(state = languechoisi, action) {
    switch (action.type) {
      case "changer":
        return action.payload;
      default:
        return state;
    }
  }

  function CompteUtil(state = user, action) {
    switch (action.type) {
      case "infouser":
        return action.payload;
      default:
        return state;
    }
  }




function array1Reducer(state = initArray1, action) {
  switch (action.type) {
    case "ADD_ITEM_ARRAY1":
      return [...state, action.payload];
    case "REMOVE_ITEM_ARRAY2":
      return state.filter((item) => item != action.payload);
    case "CLEAR_ARRAY1":
      return [];
    default:
      return state;
  }
}

function array2Reducer(state = initArray1, action) {
  switch (action.type) {
    case "ADD_ITEM_ARRAY1":
      return [...state, action.payload];
    case "REMOVE_ITEM_ARRAY2":
      return state.filter((item) => item != action.payload);
    case "CLEAR_ARRAY1":
      return [];
    default:
      return state;
  }
}

export {
  array1Reducer,
  array2Reducer,
  actualite,
  sensibilisation,
  categ,
  recommandation,
  ExoModule,
  ChangerLangue,
  Getlesson,
  Getexercice,
  getMots,
  CompteUtil
};
