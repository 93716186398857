import {
  faEarth,
  faGraduationCap,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notificationCli_API } from "../../service/config";
import Notif from "../../service/context/NotifContext";
import { useSelector } from "react-redux";

function Footer(props) {
  const [nombrmes, setnombrmes] = useState(0);
  const navigate = useNavigate()
  const abortController = new AbortController();
  const dataRedux = useSelector(state=>state.compte)

  const [show, setShow] = useState(true);
  const getAllNotification = async () => {
    try {
      axios.get(notificationCli_API,
        {
        signal: abortController.signal,
        }
        ).then((list) => {
        console.log(list);

        let i = 0;
        for (let index = 0; index < list.data.data.length; index++) {
          const element = list.data.data[index];
          if (element.is_read === false) {
            i++;
          }
        }
        setnombrmes(i);
        setTimeout(() => {
          getAllNotification()
        }, 5000);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Notifcomponent = ()=> {
    return  nombrmes > 0 ?   (<span
      className="avatar avatar-sm bg-success rounded-circle text-light"
      style={{ height: "15px", width: "15px" }}
    >
      {nombrmes}
    </span>) : null
  }
  useEffect(() => {
    getAllNotification();
    return () => {
      abortController.abort();
    };

  }, []);

  useEffect(() => {
    Notifcomponent();
  }, [nombrmes]);

  return (
    <div className="bg-dark p-2 shadow-sm w-100">
      <footer className="bg-dark container d-flex flex-wrap justify-content-between align-items-center gap-2 py-1 my-4">
        <p className="col-md-4 mb-0 text-white">©2023 Permis-de-conduire.mg</p>
        {/* <a href="#nav-home">
          <div className="col-md-4 d-flex align-items-center justify-content-center gap-3 mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
            <FontAwesomeIcon icon={faHome} color="white" />
            <FontAwesomeIcon icon={faGraduationCap} color="white" />
            <FontAwesomeIcon icon={faEarth} color="white" />
          </div>
        </a> */}

        <ul className="nav col-md-4 justify-content-end">
      {dataRedux?.type_util != "Grand public" && <li className="nav-item">
            <div onClick={()=>{
               window.scrollTo({top:0,behavior:'smooth'});
               navigate("/acc_exo")
            }} className="nav-link px-2 text-white">
              Exercice/simulations
            </div>
          </li>}
          <div className="vr"></div>
          <li className="nav-item border-right border-white">
            <Link to="/notification" onClick={()=>setShow(false)} className="nav-link px-2 text-white">
              Boite de notification{" "}
           <Notifcomponent/>
            </Link>
          </li>
          <div className="vr"></div>
          <li className="nav-item">
            <Link to="/contact" className="nav-link px-2 text-white">
              Contact
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default React.memo(Footer);
