import axios from "axios";
import React from 'react'
import { PROFILE_API } from "../config";
import { useDispatch } from "react-redux";
import {store} from "../Redux/store"

const profile = async () => {
    try {
      await axios
        .get(PROFILE_API)
        .then((response) => {
          console.log(response.data);
          store.dispatch({type:"infouser", payload: response.data})
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  export  {
profile
  }